import React from 'react';
import Graph from '../components/Graph';

const Population = () => {

    return (
        <div className='Population Content'>
            <Graph />
        </div>
    )
}

export default Population